<template>
  <b-row>
    <b-col cols="12">
      <p class="form-title mb-3">Dados do Contratado Executante</p>
    </b-col>
    <ContractedField
      label="Contratado executante"
      id="contractor_name"
      :readonly="readonly"
      :vModel="form.contractor_name"
      :validated="validated"
      :contracteds="contracteds"
      @select-contracted="selectContracted"
    />

    <b-col cols="4">
      <label for="operator_code">
        Cód. na oper. / CPF / CNPJ
      </label>
      <b-form-input
        placeholder="Descrever"
        autocomplete="off"
        class="form-control"
        v-model="form.operator_code"
        :readonly="readonly"
      />
    </b-col>

    <b-col cols="3">
      <label for="operator_code">
        Código CNES
      </label>
      <b-form-input
        placeholder="Descrever"
        autocomplete="off"
        class="form-control"
        v-model="form.contractor_cnes"
        :readonly="readonly"
      />
    </b-col>

    <b-col cols="12">
      <hr id="hr" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ExecutorContractorDataModule',
  props: {
    form: Object,
    guide: String,
    readonly: Boolean,
    validated: Boolean,
    contracteds: Array
  },
  components: {
    ContractedField: () =>
      import('@/components/Tiss/Guides/Modules/ContractedField')
  },
  methods: {
    selectContracted(value) {
      this.form.contractor_name = value?.label
      this.form.operator_code = value?.operator_code
      this.form.contractor_cnes = value?.cnes
    }
  }
}
</script>
